<script setup>
  import { ref, computed, onMounted, onBeforeUnmount,defineProps, defineEmits } from 'vue'
  import DropDownDetails from './DropDownDetails.vue';
  import HoverableIcon from './hoverableIcon.vue';

  const props = defineProps(['card']);
  const emit = defineEmits(['close-card']);

  const carddetails = ref(null);
  const clicknum = ref(0);
  const screenWidth = ref(window.innerWidth);

  const pastCities = computed(() => {
    if (Array.isArray(props.card.photog_pastcities)) {
      return props.card.photog_pastcities.map(city => ({ title: city }));
    }
    return [{ title: 'None' }];
  });

  const specialties = computed(() => {
    if (Array.isArray(props.card.photog_specialties)) {
      return props.card.photog_specialties.map(specialty => ({ title: specialty }));
    }
    return [];
  });

  const dynamicImage = computed(() => {
    if (screenWidth.value < 768) {
      return props.card.photog_logo_url || 'https://tools.erinthompsonphoto.com/public/Images/Logos/DefaultLogo.png';
    } else {
      return props.card.photog_headshot_url || 'https://tools.erinthompsonphoto.com/public/Images/Headshots/DefaultHeadshot.png';
    }
  });

  const dynamicBranch = computed(() => {
    const branch = props.card.photog_branch;
    let imagepath = '';
    switch (branch) {
      case 'Air Force':
        imagepath = require("@/assets/USAF.png");
        break;
      case 'Army':
        imagepath = require("@/assets/USA.png");
        break;
      case 'Navy':
        imagepath = require("@/assets/USN.png");
        break;
      case 'Marines':
        imagepath = require("@/assets/usmc.png");
        break;
      case 'Coast Guard':
        imagepath = require("@/assets/USCG.png");
        break;
      case 'Space Force':
        imagepath = require("@/assets/USSF.png");
        break;
      default:
        imagepath = require("@/assets/USAF.png");
        console.warn(`Unknown branch: ${branch}`);
    }
    return imagepath;
  });

  const formatUrl = (url) => {
    if (typeof url !== 'string') {
      return '';
    }
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    } else if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    }
    return url;
  };

  const goToWebsite = () => {
    const formattedUrl = formatUrl(props.card.photog_website);
    window.open(formattedUrl, '_blank');
  };

  const updateScreenWidth = () => {
    screenWidth.value = window.innerWidth;
  };

  const closeCard = () => {
    emit('close-card');
  };

  const handleClickOutside = (event) => {
    if (props.card && carddetails.value && !carddetails.value.contains(event.target) && clicknum.value > 0) {
      if (event.target.tagName.toLowerCase() !== 'a') {
        closeCard();
      }
    }
    clicknum.value++;
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    window.addEventListener('resize', updateScreenWidth);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
    window.removeEventListener('resize', updateScreenWidth);
  });

  const fullCity = computed(() => {
   return props.card.photog_currentcity
  });
</script>

<template>
  <div class="scrolling">
    <div class="card-details-container" ref="mobilecarddetails" v-if="card">
      <div class="close-icon" @click="closeCard">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#484a4b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
      <img :src="dynamicImage" alt="Dynamic Image" class="logo" />
      <h1 class="business">{{ card.photog_business }}</h1>
      <h2 class="email">{{ card.photog_email }}</h2>
      <div class="split-right-section">
        <div class="group">
          <p class="bold">Name</p>
          <p>{{ card.photog_name }}</p>
        </div>
        <div class="group">
          <div class="fit">
            <p class="bold">Current Location</p>
            <HoverableIcon class="overflow" v-if="card.photog_currentcity.length>25" :popupContent="fullCity"></HoverableIcon>
          </div>
          <p>{{ card.photog_currentcity }}</p>
          
        </div>
        <div class="group">
          <p class="bold">Previous Locations</p>
          <DropDownDetails class="Dropdown" ref="locationDropdown" title="Previous Locations" :items="pastCities" />
        </div>
        <div class="group">
          <p class="bold">Specialties</p> 
          <DropDownDetails class="Dropdown" ref="specialtyDropdown" title="Specialties" :items="specialties" />
        </div>
        <button v-if="card.photog_website" class="website-button" @click="goToWebsite">GO TO WEBSITE</button>
        <div class="group">
          <div class="socials"> 
            <a v-if="card.photog_insta" :href="card.photog_insta" target="_blank">
              <img src="@/assets/insta.png" alt="Instagram" class="social-icon" />
            </a>
            <a v-if="card.photog_fb" :href="card.photog_fb" target="_blank">
              <img src="@/assets/fb.png" alt="Facebook" class="social-icon" />
            </a>
            <a v-if="card.photog_yt" :href="card.photog_yt" target="_blank">
              <img src="@/assets/yt.png" alt="YouTube" class="social-icon" />
            </a>
            <a v-if="card.photog_tt" :href="card.photog_tt" target="_blank">
              <img src="@/assets/tiktok.png" alt="tiktok" class="social-icon" />
            </a>
            <a v-if="card.photog_pin" :href="card.photog_pin" target="_blank">
              <img src="@/assets/pinterest.png" alt="pinterest" class="social-icon" />
            </a>
            <div class="branch-icon">
              <img :src="dynamicBranch" alt="BranchImage" class="branch"/>
            </div>
          </div> 
          <p class="updatedDate">Last Updated: {{ card.photog_updated }}</p>
        </div>              
      </div>
    </div>
  </div>
</template>

<style scoped>

  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  .fit{
    display: flex;
  }
  .overflow{
    position: absolute;
    width: 0px;
  }
  .scrolling{
    position: absolute;
    display:flex;
    justify-content: center;
    overflow-y: visible;
    top:100px;
    height: fit-content;
    scrollbar-width: none;
    width: 100vw;
  }
  .card-details-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width:90%;
    background-color: #faf7f0;
    border-radius: 0.5rem;
    height:fit-content; 
    align-items: center;
    margin-bottom: 185px;
    padding-bottom:15px;
  }
  .close-icon{
    height:35px;
  }
  .close-icon svg {
    left:40vw;
    top:5px;
  }
  .logo{
    max-height: 135px;
    max-width: 100px;
    display: block;
    margin-bottom: 10px;
  }
  .updatedDate{
    font-size:14px;
    top:10px;
  }
  .branch{
    max-height: 30px;
    padding:0px 5px;
  }
  .business{
    font-size:26px;
    color:#0081a7;
  }
  .email{
    margin:15px;
    font-size:14px;
    color:#0081a7;
    font-family: 'montserrat'
  }
  .group{
    margin-bottom:15px;
  }
  .group p{
    font-family: 'montserrat';
    font-size: 16px;
    color:#484a4b;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width:200px;
  }
  .bold{
    font-weight: 600;
  }
  .website-button{
    border:none;
    background-color: #f4899c;
    color:#fff;
    height:40px;
    width:75%;
    margin-bottom: 15px;
  }
  .socials{
    display: flex;
    justify-content: center;
  }
  .socials a{
    padding:0px 5px;
  }
  .social-icon{
    height:30px;
    padding:0px 0px;
  }
</style>