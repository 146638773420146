<script setup>
  import { reactive } from 'vue'
  import  apiClient from '@/apiClient.js'

  const formData = reactive({
    first_name: '',
    email: ''
  })

  const notification = reactive({
    message: '',
    type: '',
    show: false
  })

  const closeDialog = () => {
    notification.show = false
  }

  const handleSubmit = async () => {
    try {
      const response = await apiClient.post('/subscribe-map', {
        email: formData.email,
        first_name: formData.first_name
      })
      console.log('Subscription to sequence successful:', response.data)
      resetForm()
      showNotification("You're in!", 'success')
    } catch (error) {
      console.error('Error subscribing to sequence:', error.response?.data || error.message)
      showNotification('Error subscribing. Please try again.', 'error')
    }
  }

  const showNotification = (message, type) => {
    notification.message = message
    notification.type = type
    notification.show = true
  }

  const resetForm = () => {
    formData.first_name = ''
    formData.email = ''
  }
</script>
<template>
  <div class="body">
    <div class="frame1">
      <div class="box1">
        <h2>Stay Tuned!</h2>
        <p> COST OF DOING BUSINESS CALCULATOR<br> coming soon!</p>
      </div>
    </div>
    <div class="frame2">
       <div class ="bottom">
        <h1>Want to be the first to know?</h1>
        <p>Drop your email here and I'll send you a note on launch day!!</p>
      </div>
      <form @submit.prevent="handleSubmit">
        <input v-model="formData.first_name" class="textinput" type="text" id="fname" name="fname" placeholder="First Name" required>
        <input v-model="formData.email" class="textinput" type="text" id="email" name="email" placeholder="Email" autocomplete="email" required>
        <button type="submit">Count Me In!</button>
      </form>
    </div>
    <div v-if="notification.show" :class="['notification', notification.type]">
      <p class="close-x" @click="closeDialog">X</p>
      {{ notification.message }}
    </div>
  </div>
</template>
    
<style scoped>
    .body{
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y:scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
     .frame1{
      top:200px;
      height:500px;
      display: flex;
      justify-content: center;
      margin-bottom:100px;
     }

     .box1{
     width:500px;
     height: 300px;
     background-image: url('~@/assets/map.png');
     background-repeat: no-repeat;
     background-position: center;
     padding: 20px;
     background-color: #fff;
     }

     h2{
      font-weight:Bold;
      color:#f4899c;
      font-size: 85px;
      text-align: center;
      top:-145px;
     }

     .box1 p{
      font-family: 'montserrat';
      font-weight:bold;
      font-size: 28px;
      text-align: center;
      top:-125px;
      color:#48484c
     }

     .frame2{
      height:450px;
      width: 790px;
      font-family: 'montserrat';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,.9);
     }
    .bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bottom h1{
      font-family: 'Amaranth';
      font-weight: normal;
      color: #0081A7;
      font-size: 40px;
      top:10px
    }

    .bottom p{
      top:-20px;
      font-size:16px;
    }
    
    form{
      display:flex;
      flex-direction: column;
      margin:25px;
      padding:15px 100px;
      width:500px;
      height: 200px;
      top:-20px;
    }

    .textinput{
      margin-top:10px;
      background-color: #faf7f0;
      width: 70%;
      padding:15px;
      align-self: center;
      border: solid #48484c 1px;
    }
    
    button{
      border:none;
      background-color: #f4899c;
      font-family: 'montserrat';
      align-self: center;
      margin-top:25px;
      padding:15px;
      width:50%;
      font-size: 18px;
      color: #fff;
    }
    
    button:hover{
      background-color: #d87a8b;
      cursor:pointer;
    }
    </style>
  