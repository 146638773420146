<script setup>
</script>

<template>
  <div class="not-found">
    <div class="box">
      <h1 class="header404">404 - Page Missing</h1>
      <p class ="p404">
        Oh no! You've wandered off somewhere that doesn't seem to exist.<br><br>
        Looking for something in particular? Feel free to email me at erin@erinthompsonphoto.com. If not, head back the Tools Homepage and see what else I have to show you.
      </p>
      <nav class="buttons">
        <a><router-link class="link" to="/homeDisplay">GO HOME</router-link></a>
      </nav>
    </div>
  </div>
</template>
    
  <style scoped>
  .not-found {
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box{
    margin-top:150px;
    width: 66vw;
    background: rgba(247,245,242,.7);
    padding: 40px;
  }

  .header404{
    font-family: 'montserrat';
    color: #0081A7;
    font-weight:normal;
    font-size: 35px;
  }

  .p404{
    font-family: 'montserrat';
    font-size: 15px;
  }

  .buttons{
    display: flex;
    justify-content: space-evenly;
    margin-top:50px;
    margin-bottom: 33px;
  }

  a.link{
    background-color: #cddfcd;
    font-family:'montserrat';
    font-weight: bold;
    padding:10px 30px 10px 30px;
    top:10px;
    text-decoration: none;
    color:#484a4b;
  }  
  </style>