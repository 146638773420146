<script setup>
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { useToolStore } from '@/stores/toolStore'
import { storeToRefs } from 'pinia'

const store = useToolStore()
const { isMobile } = storeToRefs(store)

const screenTools = computed(() => {
    return isMobile.value
        ? ['mobileSearchTool', 'mobileMapTool']
        : ['searchTool', 'mapTool'];
})

const getButtonText = (tool) => {
    const textMap = {
        searchTool: 'SEARCH',
        mapTool: 'MAP',
        mobileSearchTool: 'SEARCH',
        mobileMapTool: 'MAP'
    };
    return textMap[tool];
}

const updateScreenTools = () => {
    store.determineMobile();
};

onMounted(() => {
    updateScreenTools();
    window.addEventListener('resize', updateScreenTools);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScreenTools);
});
</script>

<template>
  <div class="scrolling">
  <div class="container">
    <div class="header">
      <h1 class="normal">Let's Make Moving</h1><h1 class="italics">Easier</h1>
    </div>
    <div class="container2">
      <div class="Left">
        <div class="box">
          <p class="body">
            Hey there! <span class="highlight">Erin here.</span><br><br>If you’re a milspouse photographer, you’re in the right place!<br><br>
            This toolkit is filled with resources to help you research your new location, 
            connect with other photographers, and build a business that thrives despite frequent moves.
          </p>
          <div class="buttons">
            <button v-for="tool in screenTools" :key="tool" @click="$router.push(tool)">
              {{ getButtonText(tool) }}
            </button>
        </div>
      </div>
      <div class="Right">
        <img class="erin" src="@/assets/headshot-1.jpg"/>
      </div>
    </div>
    </div>
  </div>
  </div>
</template>

  
<style scoped>
    .scrolling{
      display:flex;
      justify-content: center;
    }
    .container{
      display:flex;
      flex-direction: column;
      margin-top:250px;
      max-width: 1100px;
      padding:0px 20px;
      justify-self: center;
    }
    .container2{
      display:flex;
    }
    .Left{
      height:100%;
      width: 175%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .box{
      background-color: rgba(255,255,255,.5);
      height:330px;
      width: 95%;
      max-width: 747px;      
    }
    h1 {
      font-size: 80px;
      font-family: 'amaranth';
      color: #0081a7;
      font-weight: normal;
      top:-75px;
      z-index: 4;

    }
    .header{
      display: flex;
      height:35px;
      justify-content: center;
    }
    .italics {
      font-style: italic;
      margin-left:15px;
    }
    .body{
      font-family: 'montserrat';
      color: #484A4B;
      font-size:20px;
      padding:25px;
      top: 10px;
    }
    .highlight{
      color:#f4899c;
      font-weight: bold;
    }
    .buttons{
      display:flex;
      justify-content: center;
      margin:0px 5px;
    }
    .buttons button{
      background-color:#BBDEF0;
      color:#0081a7;
      font-family:'amaranth';
      font-size:18px;
      border:none;
      margin:0px 10px;
      padding: 10px 35px;
      width: 240px;
    }
    .buttons button:hover{
      cursor:pointer;
      background-color:#afd8ec
    }
    .erin{
      top: 2px;
      height: 315px ;
      padding:7.5px;
      background-color: #fff;
    }
    .Right p{
      color:#f4899c;
      font:15px;
      top:-40px;
    }

    @media (max-width: 1000px){
      h1{
        font-size: 55px;
        top:-40px;
      }
      .body{
        font-size: 18px;
      }
      .box{
        width: 594px;
      }
    }
    @media (max-width: 840px) {
      .scrolling{
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .container{
        display:flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-top:50px;
        padding:0px 5px;
        min-height: 100vh;
      }
      .header{
        top:-25px;
      }
      .header h1{
        font-size:30px;
      }
      .container2{
        flex-direction: column-reverse;
      }
      .box{
        top:-100px;
        height:530px;
        width: 95%;
        background-color: rgba(255,255,255,.7);
      }
      .body{
        top:70px;
        font-size: 20px;
      }

      .Left{
        top:75px;
        width:100%;
        flex-direction: column-reverse;
      }
      .Right{
        top:150px;
      }
      .buttons button{
        width:33vw;
        height:50px;
        font-size: 16px;
        top:50px;
      }
      .erin{
        height:250px;
        top:-150px;
      }
    }
</style>