<script setup>
import { useRouter } from 'vue-router'
import { useToolStore } from '@/stores/toolStore'

const router = useRouter()
const store = useToolStore()

const navToPhotogs = () => {
  store.handleNavToPhotogs(router,'desktop')
}

const navToMaps = () => {
  router.push('mapTool')
}

const returnToETP = () => {
  window.location.href = 'https://erinthompsonphoto.com/education'
}

const navToCODB = () => {
  window.open('https://docs.google.com/spreadsheets/d/11PuqDKQHbvN7kym0AVXT5w7r8JNnZ7YQF_42SvAu-C0/template/preview', '_blank', 'noopener,noreferrer')
}
</script>

<template>
  <div class="header-container">
    <header>
      <div class="left-text">
        <h1><router-link to="/homeDisplay" style="color: #0081A7;">Photographers Changing Stations</router-link></h1>
      </div>
      <nav>
        <a @click="navToPhotogs" style="color: #484A4B; cursor: pointer;">SEARCH FOR PHOTOGRAPHERS</a>
        <a @click="navToMaps" style="color: #484A4B; cursor: pointer;">LOCATIONS MAP</a>
        <a @click="navToCODB" style="color: #484A4B; cursor: pointer;">COST OF DOING BUSINESS</a>
        <div class="right-button">
          <button @click="returnToETP">Return to ETP</button>
        </div>
      </nav>
    </header>
  </div>
</template>

<style scoped>
  .header-container {
    width: 100%;
    background-color: #FAF7F0;
    height: 90px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  h1 {
    font-size: x-large;
    white-space: wrap;
    overflow: hidden;
    font-weight: lighter;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-top: 11px;
  }

  .left-text {
    color: #0081A7;
    font-family: Amaranth;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right:25px;
  }

  nav {
    display: flex;
    align-items: center;
    font-size: small;
    white-space: wrap;
    overflow: hidden;
    height: 100%;

  }

  nav a {
    margin-right: 1vw;
    font-family: 'montserrat';
    display: flex;
    align-items: center;
    height: 100%;
    user-select: none;
  }

  a {
    text-decoration: none;
    height: 100%;
  }

  .right-button button {
    background-color: #F4899C;
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .right-button button:hover {
    background-color: #e78f9c;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
      .header-container {
        height:40;
      }
      h1 {
        font-size: 105%;
      }
      .right-button button {
        height:50px;
      }
      nav{
        font-size: 75%;
      }
    }
</style>