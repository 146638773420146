<script setup>
  import mobileMenu from './mobileMenu.vue'
  import { useToolStore } from '@/stores/toolStore'
  import { storeToRefs } from 'pinia'

  const store = useToolStore()
  const {isMenuOpen} =storeToRefs(store)

  const toggleMenu = () =>{
        isMenuOpen.value = !isMenuOpen.value;
      };

</script>

<template>
    <div class="header-bar">
        <header class="left-link">
            <router-link to="/homeDisplay" style="color: #0081A7;">Photographers Changing Stations</router-link>
        </header>
        <div class="hamburger-menu" @click="toggleMenu">
            <svg viewBox="0 0 100 80" width="40" height="40" fill="#484A4B">
                <rect width="100" height="10"></rect>
                <rect y="30" width="100" height="10"></rect>
                <rect y="60" width="100" height="10"></rect>
            </svg>
        </div>
        <mobileMenu v-if="isMenuOpen"/>
    </div>
</template>
  
<style>
    *{
        text-decoration: none;
        position:relative;
    }
    .header-bar{
        width: 100%;
        background-color: #FAF7F0;
        height: 8vh;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
    }
    .left-link{
        text-align: left;
        color: #0081A7;
        font-size: 115%;
        padding-left: 20px;
        width: 80%;
    }
    .hamburger-menu {
        top:10px;
        padding-right: 20px;
        color: #484A4B;
        font-size: 30px; 
        cursor: pointer;
        width:50px;
        height: 50px;
        
    }

    .mobileMenu{
        width:50%;
    }
</style>