<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToolStore } from '@/stores/toolStore'
  import hoverableIcon from './hoverableIcon.vue';
  import DropDownForm from './DropDownForm.vue';
  import apiClient from '@/apiClient.js';
  import { abbreviateBaseName, refineCountryName, urlCheckTrim } from './inputclean';

  const store = useToolStore()
  const router = useRouter();

  const firstName = ref('');
  const lastName = ref('');
  const businessName = ref('');
  const email = ref('');
  const website = ref('');
  const fbURL = ref('');
  const igURL = ref('');
  const ptURL = ref('');
  const ttURL = ref('');
  const ytURL = ref('');
  const currentCity = ref('');
  const selectedOption = ref('United States');
  const currentState = ref('');
  const currentCountry = ref('');
  const currentBase = ref('');
  const branch = ref('');
  const milSpouse = ref(false);
  const emailConsent = ref(false);

  const tempUSCity = ref('');
  const tempUSState = ref('');
  const tempOSCity = ref('');
  const tempCountry = ref('');
  const headshotFile = ref(null);
  const headshotExtension = ref(null);
  const logoExtension = ref(null);
  const logoFile = ref(null);
  const showTempUS = ref(false);
  const showTempOS = ref(false);
  const headshotPreview = ref('');
  const logoPreview = ref('');
  const showSpecialty = ref(false);
  const tempInput = ref(null);
  const previousUSLocations = ref([]);
  const previousOSLocations = ref([]);
  
  const specialties = ref([
    {title: "Family", selected: false},
    {title: "Maternity", selected: false},
    {title: "Studio Newborn",selected: false},
    {title: "Lifestyle Newborn",selected: false},
    {title: "Seniors",selected: false},
    {title: "Couples", selected: false},
    {title: "Weddings",selected: false},
    {title: "Military Events", selected:false},
    {title: "Boudoir",selected:false},
    {title: "Brands", selected:false},
    {title: "Studio",selected:false}
  ]);

  const branches = ref([
    {title: 'Air Force'},
    {title: 'Army'},
    {title:'Coast Guard'},
    {title:'Marines'},
    {title:'Navy'},
    {title:'Space Force'}
  ]);

  const states = ref([
    {title: 'AK'}, {title: 'AL'},
    {title: 'AK'}, {title: 'AL'}, {title: 'AR'}, {title: 'AZ'}, {title: 'CA'},
    {title: 'CO'}, {title: 'CT'}, {title: 'DC'}, {title: 'DE'}, {title: 'FL'}, 
    {title: 'GA'},
    {title: 'HI'}, {title: 'IA'}, {title: 'ID'}, {title: 'IL'}, {title: 'IN'},
    {title: 'KS'}, {title: 'KY'}, {title: 'LA'}, {title: 'ME'}, {title: 'MD'},
    {title: 'MA'}, {title: 'MI'}, {title: 'MN'}, {title: 'MO'}, {title: 'MS'},
    {title: 'MT'}, {title: 'NC'}, {title: 'ND'}, {title: 'NE'}, {title: 'NH'},
    {title: 'NJ'}, {title: 'NM'}, {title: 'NV'}, {title: 'NY'}, {title: 'OH'},
    {title: 'OK'}, {title: 'OR'}, {title: 'PA'}, {title: 'RI'}, {title: 'SC'},
    {title: 'SD'}, {title: 'TN'}, {title: 'TX'}, {title: 'UT'}, {title: 'VT'},
    {title: 'VA'}, {title: 'WA'}, {title: 'WI'}, {title: 'WV'}, {title: 'WY'}

  ]);



  onMounted(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('touchend', handleClickOutside);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
    document.removeEventListener('touchend', handleClickOutside);
  });

  const handleResize = () => {
    store.determineMobile();
  };

  const specialtyInputRef = ref(null);

  const handleClickOutside = (event) => {
    if (specialtyInputRef.value && !specialtyInputRef.value.contains(event.target)) {
      addSpecialty();
    }
  };


  const removeLoc = (num, place) => {
    if (place === 'US') {
      previousUSLocations.value = previousUSLocations.value.filter(location => location.index !== num);
    } else if (place === 'OS') {
      previousOSLocations.value = previousOSLocations.value.filter(location => location.index !== num);
    }
  };

  const saveTemp = (place) => {
    let number = null;
    if (place === 'US') {
      if (tempUSCity.value && tempUSState.value) {
        showTempUS.value = false;
        number = previousUSLocations.value.length + 1;
        previousUSLocations.value.push({index: number, city: tempUSCity.value, state: tempUSState.value});
        tempUSCity.value = '';
        tempUSState.value = '';
      } else {
        alert('Please enter a city and a state.');
      }
    } else if (place === 'OS') {
      if (tempOSCity.value && tempCountry.value) {
        showTempOS.value = false;
        number = previousOSLocations.value.length + 1;
        previousOSLocations.value.push({index: number, city: tempOSCity.value, country: tempCountry.value});
        tempOSCity.value = '';
        tempCountry.value = '';
      } else {
        alert('Please enter a city and a country.');
      }
    }
  };

  const showTemp = (place) => {
    if (place === "US") {
      showTempUS.value = true;
    } else if (place === "OS") {
      showTempOS.value = true;
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addSpecialty();
    }
  };

  const showInput = () => {
    showSpecialty.value=true;
  }
  const addSpecialty = () => {
    if (tempInput.value.trim() !== "") {
      specialties.value.push({ title: tempInput.value, selected: true });
      tempInput.value = "";
      showSpecialty.value = false;
    }
  };

  const handleFileChange = (event, type) => {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        if (file.size <= 1048576) {
          previewImage(file, type);
        } else {
          alert('File size must be less than 1MB');
          fileInput.value = '';
        }
      } else {
        alert('Only PNG and JPG files are allowed');
        fileInput.value = '';
      }
    }
  };

  const previewImage = (file, type) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target.result;
      if (type === 'headshot') {
        headshotFile.value = file;
        headshotPreview.value = result;
        headshotExtension.value = file.type === 'image/png' ? '.png' : '.jpg';
      } else if (type === 'logo') {
        logoFile.value = file;
        logoPreview.value = result;
        logoExtension.value = file.type === 'image/png' ? '.png' : '.jpg';
      }
    };
    reader.readAsDataURL(file);
  };

  const getPastCities = () => {
    const pastCities = [];

    for (const location of previousUSLocations.value) {
      pastCities.push(`${location.city}, ${location.state};`);
    }

    for (const location of previousOSLocations.value) {
      pastCities.push(`${location.city}, ${location.country};`);
    }

    return pastCities.join(' ');
  };

  const handleSubmit = async () => {
    event.preventDefault();
    const requiredFields = [
      'firstName',
      'lastName',
      'businessName',
      'email',
      'currentCity',
      'branch'
    ];
    const urlFields = ['website',
      'fbURL', 
      'igURL', 
      'ptURL', 
      'ttURL', 
      'ytURL'
    ];
    for (const field of requiredFields) {
      if (!eval(field).value) {
        alert(`Please fill out the ${field} field.`);
        return;
      }
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email.value)) {
            alert('Please enter a valid email address.');
            return;
        }
    let urlPass = new Array(6).fill('');
    urlPass = urlCheckTrim(urlFields);
    if(!urlPass){
      return
    }

    if (!milSpouse.value) {
      alert('Please confirm that you are a military spouse photographer.');
      return;
    }

    if (!emailConsent.value) {
      alert('Please confirm that you agree to receiving emails from Erin Thompson Photography.');
      return;
    }
    firstName.value = firstName.value.trim();
    lastName.value = lastName.value.trim();
    businessName.value = businessName.value.trim();
    email.value = email.value.trim();
    website.value = handleURLs(urlPass[0])
    fbURL.value = handleURLs(urlPass[1])
    igURL.value = handleURLs(urlPass[2])
    ptURL.value = handleURLs(urlPass[3])
    ttURL.value = handleURLs(urlPass[4])
    ytURL.value = handleURLs(urlPass[5])
    currentCity.value = currentCity.value.trim();
    var fullBase = currentBase.value.trim();
    currentBase.value = abbreviateBaseName(fullBase);
    currentCountry.value = currentCountry.value ? refineCountryName(currentCountry.value) : 'USA';

    const submitObject = {
      'photog_index': null,
      'photog_name': `${firstName.value} ${lastName.value}`,
      'photog_email': email.value,
      'photog_business': businessName.value,
      'photog_specialties': specialties.value.filter(specialty => specialty.selected).map(specialty => specialty.title.trim()).join(', '),
      'photog_website': website.value,
      'photog_insta': igURL.value,
      'photog_fb': fbURL.value,
      'photog_yt': ytURL.value,
      'photog_tt': ttURL.value,
      'photog_pin': ptURL.value,
      'photog_branch': branch.value,
      'photog_dutystation': abbreviateBaseName(currentBase.value.trim()),
      'photog_currentcity': `${currentCity.value}, ${currentState.value}`,
      'photog_country': currentCountry.value || 'USA',
      'photog_pastcities': getPastCities(),
      'photog_updated': `${new Date().toISOString().split('T')[0]} 00:00:00.000000`
    };

    try {
      const response = await apiClient.post('/photographers', submitObject);
      const { photog_index } = response.data;
      await uploadImage(headshotFile.value, `headshot-${photog_index}${headshotExtension.value}`);
      await uploadImage(logoFile.value, `logo-${photog_index}${logoExtension.value}`);
      await handleSubscribe(firstName.value, email.value);
      resetForm();
      const dynamicPath = store.isMenuOpen ? '/mobileSearchTool' : '/searchTool';
      router.push({ 
        path: dynamicPath,
        query: {submissionComplete: 'true'}
      });
    } catch (error) {
      console.error('Error submitting photographer data:', error.response.data);
    }
  };

  const handleSubscribe = async (firstName, email) => {
    console.log('trying subscribe')
    try {
      const response = await apiClient.post('/subscribe-database', {
        email: email,
        first_name: firstName
      });
      console.log('Subscription to sequence successful:', response.data);
      resetForm();
    } catch (error) {
      console.error('Error subscribing to sequence:', error.response?.data || error.message);
    }
  };

  const handleURLs = (url) =>{
    url.trim()
    if (url.startsWith('http://') || url.startsWith('https://') || url.length===0){
      return url
    } else{
      return 'http://' + url
    }
  }

  const resetForm = () => {
    firstName.value = '';
    lastName.value = '';
    businessName.value = '';
    email.value = '';
    website.value = '';
    fbURL.value = '';
    igURL.value = '';
    ptURL.value = '';
    ttURL.value = '';
    ytURL.value = '';
    currentCity.value = '';
    currentBase.value ='';
    currentCountry.value = '';
    milSpouse.value = false;
    emailConsent.value = false;
    specialties.value = [];
    previousUSLocations.value = [];
    previousOSLocations.value = [];
    branch.value = '';
    currentState.value = '';
    headshotFile.value =null;
    logoFile.value = null;
  };

  const uploadImage = async (file, filename) => {
    if (!file) {
      console.warn('No file provided for upload');
      return;
    }
    const uploadRoute = filename.includes('headshot') ? 'upload-headshot' : 'upload-logo';
    const formData = new FormData();
    formData.append('image', file);
    formData.append('filename', filename);
    const uploadUrl = `/${uploadRoute}`;
    try {
      const response = await apiClient.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Image uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading image:', error.response ? error.response.data : error.message);
    }
  };
  const onTap = (specialty) => {
    specialty.selected = !specialty.selected;
  }
</script>

<template>
  <form class = "register-form" @submit.prevent="handleSubmit">
    <div class = "intro">
      <h1>Register Your Business</h1>
      <div class = "intent-container">
        <p class = "intent">
          Welcome to the Photographers Changing Stations (PCS) database! The intent of this tool is to connect members of the PCS community based on both current and previous locations.
        </p>
        <p class="intent">
          The information you share in this form will be searchable by anyone utilizing this tool.
        </p>
      </div>
    </div>
    <h2>Photographer Info</h2>
    <div class = "photographer-info">
      <div class = "detail-element">
        <label for="FirstName">First Name</label>
        <input id="FirstName" v-model = firstName required>
      </div>
      <div class = "detail-element">
        <label for="LastName">Last Name</label>
        <input id="LastName" v-model = lastName required>
      </div>
      <div class = "detail-element">
        <label for="BusinessName">Business Name</label>
        <input id="BusinessName" v-model = businessName required>
      </div>
      <div class = "detail-element">
        <label for="email">Email Address</label>
        <input autocomplete="on" id="email" v-model = email required>
      </div>
      <div class = "detail-element">
        <label for="website">Website</label>
        <input id="website" v-model = website>
      </div>
      <div class = "detail-element">
        <label for="fbURL">Facebook URL</label>
        <input id="fbURL" v-model = fbURL>
      </div>
      <div class = "detail-element">
        <label for="igURL">Instagram URL</label>
        <input id="igURL" v-model = igURL>
      </div>
      <div class = "detail-element">
        <label for="ptURL">Pinterest URL</label>
        <input id="ptURL" v-model = ptURL>
      </div>
      <div class = "detail-element">
        <label for="ttURL">TikTok URL</label>
        <input id="ttURL" v-model = ttURL>
      </div>
      <div class = "detail-element">
        <label for="ytURL">Youtube URL</label>
        <input id="ytURL" v-model = ytURL>
      </div>
    </div>
    <h3 class="spec">What type(s) of photography do you do? Select all that apply.</h3>
    <div class = "photography-types">
      <div class="grid-container">
        <div v-for="(specialty, index) in specialties" 
            :key="index" 
            :class="['specialty-button', { 'selected-button': specialty.selected }]"
            @click.prevent = "onTap(specialty)"
            @touchend.prevent = "onTap(specialty)">
          <span>{{ specialty.title }}</span>
        </div>
        <div v-if="showSpecialty" class="specialty-input-button">
          <input type="text" class="specialty-input" v-model="tempInput" @keypress.enter="handleEnter" ref = "specialtyInputRef" placeholder="Type your specialty" />
        </div>
        <div v-else class="specialty-button" @click="showInput">
          + Other
        </div>
      </div>
    </div>
    <div class = "image-adder">
      <div class = "image-box">
        <div>
          <label for="headshot">Headshot</label>
          <hoverableIcon popupContent="Portrait (2:3) or square headshots will display best. Max Image Size: 1MB" />
        </div>
        <input
          type="file"
          name="headshot"
          id="headshot"
          class="image-input"
          @change="handleFileChange($event, 'headshot')"
          accept="image/png, image/jpeg"
          ref="headshotInput"
        />
        <label for="headshot" class="custom-file-upload">+ Add file</label>
        <div v-if="headshotFile" class="image-preview">
          <img :src="headshotPreview" alt="Headshot Preview" />
        </div>
      </div>
      <div class="image-box">
        <div>
          <label for="logo">Logo</label>
          <hoverableIcon popupContent="Your logo will appear on white and tan backgrounds (same colors as this page). Recommended Dimensions: 65 x 65 pixels" />
        </div>
        <input
          type="file"
          name="logo"
          id="logo"
          class="image-input"
          @change="handleFileChange($event, 'logo')"
          accept="image/png, image/jpeg"
          ref="logoInput"
        />
        <label for="logo" class="custom-file-upload">+ Add file</label>
        <div v-if="logoFile" class="image-preview">
          <img :src="logoPreview" alt="Logo Preview" />
        </div>
      </div>
    </div>
    <h2>Current Assignment</h2>
    <div class = "current-location">
      <div class = "current-location-select">
        <input type="radio" id="US" v-model=selectedOption name="currentLoc" value="United States" class = "radio" checked required>
        <label for= "US" class = "radio-label">United States</label>
        <input type="radio" id="OS" v-model=selectedOption name="currentLoc" value="Overseas" class = "radio">
        <label for="OS" class = "radio-label">Overseas</label>
      </div>
      <div class = "location-details">
        <div class = "location-details-div">
          <label for="currentCity">What city do you serve? (List one.)</label>
          <input id="currentCity" v-model = currentCity required>
        </div>
        <div v-if="selectedOption" class = "location-location">
          <label  for="currentCountry" v-if="selectedOption === 'Overseas'">Country</label>
          <label v-if="selectedOption === 'United States'" >State</label>
          <input  id="currentCountry" v-if="selectedOption === 'Overseas'" v-model = currentCountry>
          <DropDownForm v-if="selectedOption === 'United States'" v-model = currentState ref="statesDropdown" title="Select a State" :items="states" :isActive="true"/>
        </div>
        <div class = "location-details-div">
          <label for="currentBase">Base Name</label>
          <input id="currentBase" v-model = currentBase placeholder = "Leave blank if classified">
        </div>
        <div class = "location-location">
          <label>Branch</label>
          <DropDownForm v-model=branch ref="branchDropdown" title="Select a Branch" :items="branches" :isActive="true" required/>
        </div>
      </div>
    </div>
    <h2>Previous Assignments</h2>
    <div class = "previous-locations">
      <h3>Please list any cities where you’ve previously run your business.</h3>
      <div class = "all-locs-desktop" v-if= "!store.isMobile">
        <div class ="location-container" v-if = "previousUSLocations.length > 0 || showTempUS">
          <div class="locLabel">
            <label>City</label>
            <label>State</label>
          </div>
          <div v-for="(location,index) in previousUSLocations" :key="index" >
            <div class="locs">
              <label class="locDisplay" >{{ location.city }}</label>
              <label class="locDisplay" >{{ location.state }}</label>
            </div>
            <label class="remove-loc" @click="removeLoc(location.index, 'US')">x</label>
          </div>
        </div>
        <div v-if = "showTempUS">
          <div class="tempLocEntry">
            <input class="tempLoc" v-model="tempUSCity">
            <DropDownForm title="Select a State" v-model="tempUSState" :items="states" :isActive="true"/>
          </div>
          <button class="tempLocSave" @click="saveTemp('US')">Save</button>
        </div>
        <div class ="location-container" v-if = "previousOSLocations.length > 0 || showTempOS">
          <div class="locLabel">
            <label>City</label>
            <label>Country</label>
          </div>
          <div v-for="(location,index) in previousOSLocations" :key="index">
            <div class="locs">
              <label class="locDisplay" >{{ location.city }}</label>
              <label class="locDisplay" >{{ location.country }}</label>
            </div>
            <label class="remove-loc" @click="removeLoc(location.index, 'OS')">x</label>
          </div>
        </div>
        <div v-if = "showTempOS" >
          <div class="tempLocEntry">
            <input class="tempLoc" v-model="tempOSCity">
            <input class="tempLocCountry" v-model="tempCountry">
          </div>
          <button class="tempLocSave" @click="saveTemp('OS')">Save</button>
        </div>
      </div>
      <div class = "all-locs-mobile" v-if= "store.isMobile">
        <div class ="location-container" v-if = "previousUSLocations.length > 0 || showTempUS">
          <div class="locUS">
            <label>City, State</label>
            <div v-for="(location,index) in previousUSLocations" :key="index">
              <div>
                <label class="locDisplay" >{{ location.city }}, {{ location.state }}</label>
              </div>
              <label class="remove-loc" @click="removeLoc(location.index, 'US')">x</label>
            </div>
            <input v-if ="showTempUS" class="tempLoc" v-model="tempUSCity" placeholder="  City">
            <DropDownForm v-if ="showTempUS" title="Select a State" v-model="tempUSState" :items="states" :isActive="true"/>
            <button v-if ="showTempUS" class="tempLocSave" @click="saveTemp('US')">Save</button>
          </div>
        </div>
        <div class ="location-container" v-if = "previousOSLocations.length > 0 || showTempOS">
          <div class="locOS">
            <label>City, Country</label>
              <div v-for="(location,index) in previousOSLocations" :key="index">
                <div>
                  <label class="locDisplay">{{ location.city }}, {{ location.country }}</label>
                </div>
                <label class="remove-loc" @click="removeLoc(location.index, 'OS')">x</label>
              </div>
            <input v-if ="showTempOS" class="tempLoc" v-model="tempOSCity" placeholder="  City">
            <input v-if ="showTempOS" class="tempLoc" v-model="tempCountry" placeholder="  Country">
            <button v-if ="showTempOS" class="tempLocSave" @click="saveTemp('OS')">Save</button>
          </div>
        </div>
      </div>
      <div class = "addButtons">
        <label class="addLoc" @click="showTemp('US')">+ US Location</label>
        <label class="addLoc" @click="showTemp('OS')">+ Overseas Location</label>
      </div> 
    </div>
    <div class = "check-boxes">
      <div>
        <input type="checkbox" id = "milCheck" v-model = milSpouse required>
        <label for = "milCheck">I am a military spouse photographer and agree to having my business information listed in this database.</label>
      </div>
      <div>
        <input type="checkbox" id = "emailCheck" v-model = emailConsent required>
        <label for = "emailCheck">I agree to receiving emails from Erin Thompson Photography.</label>
      </div>
    </div>
    <button class="submit-button" @click = "handleSubmit()">SUBMIT</button>
  </form>
</template>


<style scoped>
  *{
    margin:0;
    padding:0;
  }

  .register-form{
    position: relative;
    top: 90px;
    background-color: #faf7f0;
    height: 93vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .register-form::-webkit-scrollbar {
    display: none;
  }

  .intro{
    padding-top:25px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  h1 {
    color: #0081A7;
    font-size: 50px;
    font-weight: normal;
    z-index: 1;
  }
  
  h2{
    color: #0081A7;
    font-size: 30px;
    font-weight: normal;
    text-align: left;
    margin-left: 13vw;
    margin-top:15px;
  }
  .intent-container {
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: 'montserrat';
    font-size:14px;
    font-weight: normal;
    padding: 35px 40px 25px 40px;
    margin-top:-34px;
    margin-bottom:30px;
    width: 600px;
    text-align: center;
    z-index: 0;
  }

  .internal-intent-desktop ol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .internal-intent-desktop li{
    margin: 15px 0px 0px 0px;
    width: 75%;
    text-align: left;
    padding-left: 15px;
  }

  .intent{
    margin-top:15px;
  }

  .photographer-info{
    width: 100%;
    margin-top:35px;
    color:#0081A7;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 34vw);
    grid-auto-flow: row dense; 
    grid-auto-rows: auto;
    grid-row-gap: 1vw;
    grid-column-gap: 6vw;
  }
  .detail-element {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .detail-element label{
    padding-bottom:5px;
    font-size:18px;
  }
  .detail-element input{
    background-color: white;
    font-size: 15px;
    padding: 5px;
    margin-bottom:10px;
    width:97%;
  }

  h3{
    color:#0081A7;
    font-size: 18px;
    font-weight: normal;
    justify-content: left;
    text-align: left;
    margin-left: 13vw;
    margin-top:25px;
  }
  .spec{
    margin-bottom: 7px;
  }
  .photography-types {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.grid-container {
  display: grid;
  justify-content: center;
  width: 74vw;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top:15px;
}

.specialty-button {
  display: flex;
  align-self: center;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-family: 'montserrat';
}

.selected-button {
  background-color: #BBDEF0;
}

@media (hover: hover){
  .specialty-button:hover {
    background-color: #ecf9ff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  }
}

.specialty-button span {
  font-family: 'montserrat'
}

.other-button span{
  grid-column: span 3;
  display: flex;
  align-items: center;
  font-family:'montserrat'
}

.specialty-input-button{
  display: flex;
  align-self: center;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.specialty-input {
  width: 100%;
  background-color: #fff;
  padding: 0.7rem;
  display: flex;
  align-items: center;
}
  
.image-adder{
  display: grid;
  grid-template-columns: repeat(2, 34vw);
  grid-column-gap: 6vw;
  grid-auto-flow: row dense; 
  grid-auto-rows: auto;
  justify-items: left;
  color:#0081A7;
  font-size:18px;
  padding: 20px 13vw 20px 13vw;
}

.image-box{
  display:flex;
  flex-direction: column;
  padding: 30px 25vw 25px 0px;
  font-weight: normal;
}

.image-box div{
  text-align: left;
}

.image-input {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  width:100px;
  text-align: left;
  font-family: 'montserrat';
  font-size: 15px;
  padding: 10px 30px 10px 15px;
  cursor: pointer;
  background-color: #ffffff;
  color: #484A4B;
  border: none;
  margin-top: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-file-upload:hover {
  background-color: #ecf9ff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  display: block;
}

.current-location {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 13vw;
  margin-top:15px;
  margin-bottom:50px;
}

.current-location-select{
  display: flex;
  height: 25px;
  margin-bottom:25px;
}


.current-location input{
  background-color: #fff;
  font-family: 'montserrat';
  width:97%;
  padding:10px;
  margin-top:10px;
  font-size:18px;
  margin-right:0px;
}

.radio{
  accent-color: #0081A7;
  max-width:30px;
} 

.current-location label {
  color:#0081A7;
  font-size: 20px;
  text-align: left;
  justify-self: left;
  padding-right: 35px;
  margin-top:5px;
  width: 250px;
}

.location-details{
  display: grid;
  justify-content: left;
  grid-template-columns: repeat(2, 34vw);
  grid-auto-flow: row dense; 
  grid-auto-rows: auto;
  grid-row-gap: 1vw;
  grid-column-gap: 6vw;
}

.location-details-div {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width:300px;
}

.location-details-div label{
  width: 300px;
}

.location-details-div input::placeholder{
  font-size: 16px;
  color:#484A4B;
  opacity: .7;
}

.location-location{
  display: flex;
  justify-self: left;
  justify-items: flex-start;
  flex-direction: column;
}

.location-location label{
  margin-bottom: 0px;
}

.location-container{
  display:flex;
  flex-direction: column;
  color: #0081A7;
}

.addButtons{
  display:flex;
  margin:25px 0px 10px 13vw;
  flex-direction: row;
  color: #484A4B;
  justify-content: left;
}

.addLoc {
  margin-right:35px;
  font-size: 16px;
  background-color: #fff;
  padding:10px 0px 10px 15px;
  width:180px;
  text-align: left;
  font-family: 'montserrat';
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.addLoc:hover{
  background-color: #ecf9ff;
}

.locLabel{
  font-size:20px;
  margin-top:25px;
  margin-left:13vw;
  margin-bottom:5px;
  display: grid;
  grid-template-columns: repeat(2, 34vw);
  grid-column-gap: 6vw;
  grid-auto-flow: row dense; 
  grid-auto-rows: auto;
  justify-items: left;
}

.locs{
  font-size:18px;
  margin-top:5px;
  margin-left:13vw;
  margin-bottom:5px;
  display: grid;
  grid-template-columns: repeat(2, 34vw);
  grid-column-gap: 6vw;
  grid-auto-flow: row dense; 
  grid-auto-rows: auto;
  justify-items: left;
}

.locDisplay{
  background-color: #fff;
  font-family: 'montserrat';
  color:#484A4B;
  padding: 10px;
  width:97%;
  text-align: left;
  margin-top:10px;
}

.remove-loc{
  position: absolute;
  right: 8vw;
  top:22px;
  font-size: 18px;
  cursor: pointer;
}

.tempLocEntry{
  display:grid;
  grid-template-columns: repeat(2, 34vw);
  grid-column-gap: 6vw;
  margin-left:13vw;
  justify-items: start;
}
.tempLocEntry input{
  background-color: #fff;
  height:38px;
  padding-left:10px;
  margin-top:7px;
  width:100%;
}

.tempLocCountry{
  width:290px !important;
}

.tempLocSave{
  background-color: #F4899C;
  width:110px;
  border: none;
  padding: 0px 0px;
  color: white;
  cursor: pointer;
  font-family: 'montserrat';
  font-size: 18px;
  height: 38px;
  position: absolute;
  right:5vw;
  top:8px;
}
.check-boxes {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left:12vw;
  font-size: 16px;
  color:#484A4B;
}
.check-boxes div{
  margin:8px;
}

.check-boxes input {
  font-size: medium;
  margin-right:15px;
}

.submit-button{
  background-color: #F4899C;
  border: none;
  padding: 5px 50px;
  color: white;
  cursor: pointer;
  font-family: 'Amaranth';
  font-size: 25px;
  height: 50px;
  margin-top:35px;
  margin-bottom:50px;
  justify-content: flex-start;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button:hover {
  background-color: #ffd1da;
}

@media (max-width: 767px) {
  .register-form{
    top: 8vh;
    overflow-x: hidden;
  }
  .intro h1{
    margin-bottom: 0px;
    line-height: 1;
    font-size:36px;
    top:5px;
  }
  .intent{
    margin-bottom: 0px;
    padding-bottom:20px;
    padding-top:0px;
    font-size:14px;
    text-align:center;
    margin-top:0px;
  }
  .intent-container{
    padding-top:40px;
    width: 80vw;
    margin-top: -10px;
  }
  .internal-intent{
    padding: 10px 45px;
    font-family: 'montserrat';
    background-color: #fff;
    font-size:14px;
    margin-bottom:5px;
    height: auto;
  }
  .internal-intent-mobile p{
    margin-bottom:15px;
    font-style: italic;
    color:#F4899C;
    text-align: center;
  }
  .intent p{
    text-align: center;
  }

  h2{
    padding-top:15px;
  }
  .photographer-info{
    margin-top:20px;
  }
  h3{
    margin:25px 50px 25px 50px;
  }
  .custom-file-upload{
    width:75px;
  }
  .photographer-info{
    grid-template-columns: repeat(1, 75vw);
  }
  .grid-container{
    grid-template-columns: repeat(2,1fr);
  }
  .specialty-button{
    height: 35px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  .current-location{
    width:100vw;
    margin-bottom:30px;
  }
  .current-location-select{
    display: grid;
    grid-template-columns: .2fr 2fr;
    justify-items: start;
    align-items: center;
    height:75px;
  }
  .location-location label{
    font-size: 18px;
  }
  .location-details{
    display:flex;
    flex-direction: column;
  }
  .location-details-div{
    font-size: 18px;
  }
  .location-details-div label{
    font-size: 18px;
  }
  .location-details-div input{
    margin-top:5px;
    font-size:14px;
    padding-right:0px;
    width:285px;
  }
  .location-details-div input::placeholder{
    opacity: .7;
    font-size:16px;
  }
  .all-locs-mobile{
    text-align: left;
  }
  .all-locs-mobile label{
    margin-bottom:5px;
  }
  input#currentCountry{
    padding-right: 0px;
  }
  .location-container{
    display:flex;
    flex-direction: column;
    width:200px;
    margin: 10px 13vw;
    align-items: flex-start;
  }
  .locUS{
    display: flex;
    flex-direction: column;
    width:295px;
    margin-bottom:25px;
  }
  .locUS label{
    font-size: 18px;
  }
  .locUS div{
    margin-right:13vw;
    display: flex;
    flex-direction:row;
    width:295px;
  }
  .locOS{
    display: flex;
    flex-direction: column;
    width:295px;
    margin-bottom:10px;
  }
  .locOS label{
    font-size: 18px;
  }
  .locOS div{
    margin-right:13vw;
    display: flex;
    flex-direction:row;
    width:295px;
  }
  .inner-loc{
    display: flex;
    flex-direction: column;
  }
  .locDisplay{
    width:295px;
    padding-right:0px;
    margin-top:5px;

  }
  .locLabel{
    width:200px
  }
  .tempLoc{
    width:295px;
    padding-right:0px;
    padding:10px 0px;
    background-color: #fff;
    margin-bottom: 5px;
    margin-top:10px;
  }
  .tempLoc::placeholder{
    opacity: .7;
  }
  .tempLocSave{
    position: relative;
    top:0;
    left:0;
    margin-top:10px;
  }
  .remove-loc{
    position: relative;
    top: 12px;
    left:-75px;
  }
  .addButtons{
    display: flex;
    flex-direction: column;
    margin-top:20px;
  }
  .addLoc{
    margin:15px 0px;
    vertical-align: middle;
    align-items: center;
  }
  .check-boxes{
    width: 85%;
    margin-left:30px;
    text-align: left;
    margin-right:30px;
  }
  .check-boxes div{
    display:flex;
    flex-direction: row;
  }
  .submit-button{
    margin-bottom:0px;
    padding-bottom:10px;
    width:100%;
    height:75px;
    margin-bottom: 200px;
  }
  @media (hover: hover) and (pointer: fine) {
    .specialty-button:hover {
      background-color: #f0f0f0;
    }
  }
}
</style>