import { createRouter, createWebHistory } from 'vue-router';
import searchTool from './components/searchTool.vue';
import mapTool from './components/mapTool.vue';
import costTool from './components/costTool.vue';
import homeDisplay from './components/homeDisplay.vue';
import formsTool from './components/formsTool.vue';
import mobileSearchTool from './components/mobileSearchTool.vue';
import mobileMapTool from './components/mobileMapTool.vue';
import mobileCostTool from './components/mobileCostTool.vue';
import mobileCardDetails from './components/mobileCardDetails.vue';
import notFound from './components/notFound.vue';

const routes = [
  {
    path: '/homeDisplay',
    redirect: '/'
  },
  { path: '/searchTool', component: searchTool },
  { path: '/mapTool', component: mapTool },
  { path: '/costTool', component: costTool },
  { path: '/', component:homeDisplay},
  { path: '/formsTool', component:formsTool},
  { path: '/mobileSearchTool', component:mobileSearchTool},
  { path: '/mobileMapTool', component:mobileMapTool},
  { path: '/mobileCostTool', component:mobileCostTool},
  { path: '/mobileCardDetails',component:mobileCardDetails},
  { path: '/:pathMatch(.*)*', name: 'notFound',  component:notFound}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
