<script setup>
  import { ref, watch, defineProps, onMounted, nextTick, defineEmits } from 'vue';
  import { useToolStore } from '@/stores/toolStore'
  import DropDown from './DropDown.vue';
  import hoverableIcon2 from './hoverableIcon2.vue';
  import { storeToRefs } from 'pinia';
  import { getUniqueStates, getUniqueValues, getMatchValue, updateFilter } from './filterHelpers.js';
  import { refineCountryName } from './inputclean';

  const props = defineProps({
    photographers: {
      type: Array,
      required: true,
    },
  });

  const store = useToolStore();
  const emit = defineEmits(['filters-changed']);

  const { initialCountry } = storeToRefs(store)
  const { initialState } = storeToRefs(store)
  const { initialBase } = storeToRefs(store)
  const { initialSpecialty } = storeToRefs(store)
  const { country } = storeToRefs(store)
  const { state } = storeToRefs(store)
  const { base } = storeToRefs(store)
  const { specialty } = storeToRefs(store)
  const { nameInput } = storeToRefs(store)

  const countryDropdown = ref(null);
  const stateDropdown = ref(null);
  const baseDropdown = ref(null);
  const specialtyDropdown = ref(null);

  const emitFiltersChanged = () => {
    emit('filters-changed');
  }; 

  const populateFilterOptions = () => {
    const refinedCountries = getUniqueValues(props.photographers, 'photog_country')
        .map(refineCountryName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    initialCountry.value = refinedCountries.map(country => ({ title: country }));
    
    initialState.value = getUniqueStates(props.photographers)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .map(state => ({ title: state }));
    
    initialBase.value = Array.from(
      new Set(
        getUniqueValues(props.photographers, 'photog_dutystation')
          .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
          .map(base => ({ title: base }))
          .map(item => JSON.stringify(item))
      )
    ).map(item => JSON.parse(item));
    
    initialSpecialty.value = getUniqueValues(props.photographers, 'photog_specialties')
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
      .map(specialty => ({ title: specialty }));

    if (!country.value.length > 0 && store.countryDropdownActive){
      country.value = initialCountry.value
    }
    if (!state.value.length > 0 && store.stateDropdownActive){
      state.value = initialState.value
    }
    if (!base.value.length > 0 && store.baseDropdownActive){
      base.value = initialBase.value
    }
    if (!specialty.value.length > 0 && store.specialtyDropdownActive){
      specialty.value = initialSpecialty.value
    }
    };

  const handleBaseChange = (value) => {
    if (!store.handlingCountry && !store.handlingState){
      store.handlingBase = true;
      if (value !== 'Current Base') {
        const match = getMatchValue(value);
        stateDropdown.value.selectOption(match.state || 'State/Region');
        store.filterSt = '';
        countryDropdown.value.selectOption(match.country || 'Country');
        store.filterC = match.country || '';
        store.freezeDropdown('stateDropdown')
        store.freezeDropdown('countryDropdown')
      } else {
        store.unfreezeDropdown('stateDropdown')
        store.unfreezeDropdown('countryDropdown')
      }
      store.handlingBase = false;
    }
  };

  const handleStateChange = (value) => {
    if (!store.handlingCountry && !store.handlingBase){
      store.handlingState = true;
      if (value !== 'State/Region'){
        countryDropdown.value.selectOption('USA');
        store.filterC = ''
        store.freezeDropdown('countryDropdown')
        base.value = updateFilter('state', value, initialBase)
        if (base.value.length === 0){
          store.freezeDropdown('baseDropdown')
        }
      }
      store.handlingState = false;
    }
  };

  const handleCountryChange = (value) => {
    if (!store.handlingState && !store.handlingBase){
      store.handlingCountry = true;
      updateStateDropdownActive(value);
      if (value ==='Country'){
        return
      }
      base.value = updateFilter('country', value, initialBase)
      nextTick(() => {
        if (base.value.length === 0){
         store.freezeDropdown('baseDropdown')
        } else {
          store.unfreezeDropdown('baseDropdown')
        }
      });
      store.handlingCountry = false;
    }
  };

  const updateStateDropdownActive = (value) => {
    if (value !== 'USA' && value !== 'Country' && value !== '') {
      stateDropdown.value.selectOption('State/Region');
      store.filterSt = '';
      store.freezeDropdown('stateDropdown');
    } else {
      store.unfreezeDropdown('stateDropdown')
      store.unfreezeDropdown('baseDropdown')
    }
  };

  const resetFilters = () => {
    countryDropdown.value.selectOption('Country');
    countryDropdown.value.closeDropdown();
    stateDropdown.value.selectOption('State/Region');
    stateDropdown.value.closeDropdown();
    baseDropdown.value.selectOption('Current Base');
    baseDropdown.value.closeDropdown();
    specialtyDropdown.value.selectOption('Specialty');
    specialtyDropdown.value.closeDropdown();
    nameInput.value.value = '';
    store.unfreezeDropdown('baseDropdown');
    nextTick(() => {
      populateFilterOptions();
    });
  };

  watch(() => store.filterC, () => emitFiltersChanged());
  watch(() => store.filterSt, () => emitFiltersChanged());
  watch(() => store.filterB, () => emitFiltersChanged());
  watch(() => store.filterSp, () => emitFiltersChanged());
  watch(() => store.filterN, () => emitFiltersChanged());

  watch(() => props.photographers, (newValue) => {
    if (newValue) {
      populateFilterOptions();
    }
  });

  watch(() => store.resetFiltersFlag, () => {
    resetFilters();
  });

  onMounted(() => {
    populateFilterOptions();
  });
</script>

<template>
    <div>
        <div class="filters">
        <hoverableIcon2 v-if="store.keyVisible" popupContent="Your logo will appear on white and tan backgrounds (same colors as this page). Recommended Dimensions: 65 x 65 pixels" />
        <DropDown v-model ="store.filterC" ref= "countryDropdown" title= "Country" :items="country" :isActive="store.countryDropdownActive" @update:modelValue="handleCountryChange"/>
        <DropDown v-model ="store.filterSt" ref= "stateDropdown" title= "State/Region" :items="state" :isActive="store.stateDropdownActive" @update:modelValue="handleStateChange"/>
        <DropDown v-model ="store.filterB" ref= "baseDropdown" title= "Current Base" :items="base" :isActive="store.baseDropdownActive" @update:modelValue = "handleBaseChange"/>
        <DropDown v-model ="store.filterSp" ref= "specialtyDropdown" title= "Specialty" :items="specialty" :isActive="store.specialtyDropdownActive" />
        <input class ="name-input" v-model ="store.filterN" ref= "nameInput" title= "Name" placeholder = " Name" :disabled="!store.nameInputActive">
        </div>
    </div>
</template>

<style>
  .filters {
    position: relative;
    display:flex;
    justify-content: center;
    top:25px;
    z-index: 1;
    }

  .filters {
    display:flex;
    justify-content: space-between;
  } 

  .filters > * {
    flex: 1;
    margin: 0 20px;
  }

  input {
    border: none;
    background-color: #faf7f0;
    color : #484A4B;
    font-family: 'montserrat';
    outline: none;
    font-size: medium;
  }

  input:disabled {
    opacity: 0.5;
    cursor:none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    input {
      width:100px;
      font-size: small;
      }
    .filters > *{
      margin: 0 10px;
    }
  }
  @media (max-width: 767px) {

  .filters{
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .name-input{
    width:88.5%;
    max-width: 296px;
    text-align: center;
    background-color: #bbDef0;
    margin-right:18px;
    padding-top:5px;
    padding-bottom:5px;
  }
}
</style>