
  export function getUniqueStates(data) {
    const states = new Set();
  
    data.forEach(item => {
      if (item.photog_currentcity) {
        const parts = item.photog_currentcity.split(',');
        if (parts.length > 1) {
          const state = parts[1].trim().toUpperCase();
          if (state.length === 2) {
            states.add(state);
          }
        }
      }
      if (item.photog_pastcities && Array.isArray(item.photog_pastcities)) {
        item.photog_pastcities.forEach(city => {
          const parts = city.split(',');
          if (parts.length > 1) {
            const state = parts[1].trim().toUpperCase();
            if (state.length === 2) {
              states.add(state);
            }
          }
        });
      }
    });
    return Array.from(states);
  }

  export function getUniqueValues(data, key) {
    const values = new Set();
  
    data.forEach(item => {
      const value = item[key];
      if (Array.isArray(value)) {
        value.forEach(val => values.add(val));
      } else if (value) {
        values.add(value);
      }
    });
  
    return Array.from(values);
  }

  export function getMatchValue(base) {
    for (const [country, states] of Object.entries(baseData)) {
      if (country === "USA") {
        for (const [state, bases] of Object.entries(states)) {
          if (bases.includes(base)) {
            return { state, country };
          }
        }
      } else {
        if (states.includes(base)) {
          return { state: '', country };
        }
      }
    }
    return { state: '', country: '' };
  }

  export function updateFilter(type, selection, array) {
      switch (type) {
        case 'country':
          if (selection === 'USA') {
            return array.value.filter(base => 
              Object.values(baseData.USA).some(stateBases => 
                stateBases.includes(base.title)
              )
            );
          } else {
            return array.value.filter(base => 
              baseData[selection] && baseData[selection].includes(base.title)
            );
          }
        case 'state':
            return array.value.filter(base =>
              Object.entries(baseData.USA).some(([state, bases]) => 
                state === selection && bases.includes(base.title)
              )
            );
      }
  }

  const baseData = {
    "USA": {
      "AL": ["Ft. Novosel", "Redstone Arsenal", "Maxwell-Gunter Air Force Base"],
      "AK": ["Clear Space Force Station", "Ft. Wainwright", "Eielson AFB", "JB Elmendorf-Richardson"],
      "AZ": ["Ft. Huachuca", "Yuma Proving Ground", "Davis-Monthan AFB", "Luke AFB"],
      "AR": ["Little Rock AFB"],
      "CA": ["Naval Base Coronado", "Ft. Irwin", "Beale AFB", "Edwards AFB", "Los Angeles AFB", "March ARB", "Travis AFB", "Vandenberg Space Force Base", "NAS Lemoore", "NAWS China Lake", "NB San Diego", "MCB Camp Pendleton", "MCAS Miramar", "MCRD San Diego"],
      "CO": ["Ft. Carson", "Buckley Space Force Base", "Peterson Space Force Base", "Schriever Space Force Base", "USAF Academy"],
      "DC": ["JB Anacostia-Bolling", "Pentagon"],
      "DE": ["Dover AFB"],
      "FL": ["NAS Jacksonville", "Eglin AFB", "Hurlburt Field", "MacDill AFB", "Patrick Space Force Base", "Cape Canaveral Space Force Station", "Tyndall AFB", "NAS Pensacola", "NAS Key West"],
      "GA": ["Ft. Eisenhower", "Ft. Moore", "Ft. Stewart", "Hunter Army Airfield", "Moody AFB", "Robins AFB"],
      "HI": ["Schofield Barracks", "Tripler Army Medical Center", "JB Pearl Harbor-Hickam", "Marine Corps Base Hawaii"],
      "IL": ["Scott AFB"],
      "IN": ["Grissom ARB"],
      "KS": ["Ft. Leavenworth", "Ft. Riley", "McConnell AFB"],
      "KY": ["Ft. Campbell", "Ft. Knox"],
      "LA": ["Ft. Johnson", "Barksdale AFB"],
      "MD": ["Aberdeen Proving Ground", "Ft. Detrick", "Ft. Meade", "JB Andrews", "US Naval Academy"],
      "MA": ["Ft. Devens", "Hanscom AFB", "USCG Air Station Cape Cod", "USCG Sector SE New England"],
      "MI": ["Detroit Arsenal", "Selfridge ANGB"],
      "MS": ["Camp Shelby", "Columbus AFB", "Keesler AFB", "NAS Meridian", "NS Pascagoula"],
      "MO": ["Ft. Leonard Wood", "Whiteman AFB"],
      "MT": ["Malmstrom AFB"],
      "NE": ["Offutt AFB"],
      "NV": ["Hawthorne Army Depot", "Nellis AFB", "Creech AFB"],
      "NJ": ["JB McGuire-Dix-Lakehurst", "Picatinny Arsenal", "NWS Earle"],
      "NM": ["White Sands Missile Range", "Cannon AFB", "Holloman AFB", "Kirtland AFB"],
      "NY": ["Ft. Drum", "Ft. Hamilton", "US Military Academy", "Watervliet Arsenal"],
      "NC": ["Ft. Liberty", "Camp Mackall", "Seymour Johnson AFB", "MCB Camp Lejeune", "MCAS Cherry Point", "MCAS New River"],
      "ND": ["Grand Forks AFB", "Minot AFB"],
      "OH": ["Wright-Patterson AFB", "Youngstown Air Reserve Station"],
      "OK": ["Ft. Sill", "Altus AFB", "Tinker AFB", "Vance AFB"],
      "PA": ["Carlisle Barracks"],
      "SC": ["Ft. Jackson", "JB Charleston", "Shaw AFB"],
      "SD": ["Ellsworth AFB"],
      "TN": ["Arnold AFB"],
      "TX": ["Ft. Bliss", "Ft. Cavazos", "JB San Antonio", "Dyess AFB", "Goodfellow AFB", "Laughlin AFB"],
      "UT": ["Hill AFB", "Dugway Proving Ground"],
      "VA": ["Ft. Belvoir", "Ft. Eustis", "Ft. Gregg-Adams", "JB Myer-Henderson Hall", "JB Langley-Eustis", "NS Norfolk", "NAS Oceana", "MCAS Quantico"],
      "WA": ["JB Lewis-McChord", "Naval Base Kitsap", "NS Everett", "Naval Air Station Whidbey Island"],
      "WI": ["Ft. McCoy"],
      "WY": ["FE Warren AFB"],
      "GU": ["Andersen AFB", "Naval Base Guam"]
    },
    "England": ["RAF Alconbury", "RAF Croughton", "RAF Fairford", "RAF Lakenheath", "RAF Menwith Hill", "RAF Mildenhall"],
    "Italy": ["Aviano AB", "Camp Darby", "NSA Naples", "NAS Sigonella", "US Army Garrison Italy"],
    "Germany": ["Wiesbaden Garrison", "USAG Ansbach", "USAG Bavaria", "USAG Rheinland-Pfalz", "USAG Stuttgart", "USAG Wiesbaden", "Ramstein AB", "Spangdahlem AB"],
    "Spain": ["NS Rota", "Morón Air Base"],
    "Japan": ["Kadena AB", "Misawa AB", "Yokota AB", "Camp Zama", "MCAS Iwakuni", "Fleet Activities Yokosuka", "Fleet Activities Sasebo"],
    "South Korea": ["Camp Humphreys", "Osan AB", "Kunsan AB"],
    "Greenland": ["Thule Air Base"],
    "Cuba": ["Guantanamo Bay Naval Base"],
    "Turkey": ["Incirlik Air Base"],
    "Djibouti": ["Camp Lemonnier"],
    "Bahrain": ["Naval Support Activity Bahrain"],
    "United Arab Emirates": ["Al Dhafra Air Base"],
    "Portugal": ["Lajes Field"]
  };