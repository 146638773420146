<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useToolStore } from '@/stores/toolStore'
import headerBar from './components/headerBar.vue'
import bodyView from './components/bodyView.vue'
import mobileHeaderBar from './components/mobileHeaderBar.vue'

const store = useToolStore()
const { isMobile } = storeToRefs(store)

const windowWidth = ref(window.innerWidth)

const components = [
  headerBar,
  mobileHeaderBar
]

const headerComponent = computed(() => {
  return isMobile.value ? components[1] : components[0]
})

const handleResize = () => {
  windowWidth.value = window.innerWidth
  store.determineMobile()
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
  store.determineMobile()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <component :is="headerComponent"></component>
  <div class="body-container">
    <bodyView/>
  </div>
</template>

<style>
#app {
  font-family: Amaranth, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@media (max-width: 767px) {
  .container {
    
    padding:0;
    margin:0;  
  }
}
@media (min-width: 768px) and (max-width: 1023px) {

  .header {
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .header {
    font-size: 40px;
  }
}
</style>
