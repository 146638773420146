<script setup>

</script>

<template>
  <div class="body-view">
    <div class="background-layer"></div>
    <router-view/>
  </div>
</template>

<style scoped>
.body-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
}

.background-layer {
  background-image: url('~@/assets/backgrounddemo.jpg');
  background-size: cover;
  background-attachment: fixed;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center;
}

router-view {
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: top;
  align-items: center;
}
</style>
