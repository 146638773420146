<script setup>
import { ref, defineProps } from 'vue';
import { useToolStore } from '@/stores/toolStore';

const store = useToolStore();
defineProps({
  popupContent: {
    type: String,
    required: true
  }
});
const isPopupVisible = ref(false);
const showPopup = () => {
  isPopupVisible.value = true;
};


const hidePopup = () => {
  isPopupVisible.value = false;
};
</script>

<template>
    <div class="info-icon-container" :class="{ 'visible': store.keyVisible }" @mouseover="showPopup" @mouseleave="hidePopup">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="info-icon">
        <circle cx="256" cy="256" r="248" fill="#0081A7"/>
        <path d="M256 118c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" fill="#FFFFFF"/>
        </svg>
        <div v-if="isPopupVisible" class="popup">
            <p>Photographers outlined in <span style="font-weight: bold; color: #0081A7;">BLUE</span> are <span style="font-weight: bold; color: #0081A7;">CURRENTLY</span> serving this area. Those outlined in <span style="font-weight: bold; color: #F4899C;">PINK</span> have served it in the <span style="font-weight: bold; color: #F4899C;">PAST</span>.</p>
        </div>
    </div>
</template>
 
<style scoped>
.info-icon-container {
  position: absolute;
  display: inline-block;
  width:0px;
  left: -30px;
  top:7px;
}

.info-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: super;
}

.popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-25%);
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  border: 1px solid #ccc;
  width: 250px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: normal;
  margin-top: 5px;
}
@media (max-width: 767px) {
    .info-icon-container {
        position: absolute;
        height:0px;
        top:-20px;
        left: calc(50% - 25px)
    }
    .popup{
        background-color: #FFDBE2;
        transform: translateX(-48%);
        top:15px;
    }
}

</style>
  