import { defineStore } from 'pinia'

export const useToolStore = defineStore('Tool',{
    state: () => ({
        selectedCard: null,
        isMobile:false,
        isMenuOpen:false,
        mobileScroll:0,
        savedScrollPosition:0,
        allPhotographers: [],
        filteredPhotographers: [],
        resetFiltersFlag: false,
        filterC: '',
        filterSt: '',
        filterB: '',
        filterSp: '',
        filterN: '',
        nameInput: null,
        initialCountry:[],
        country: [],
        initialState:[],
        state: [],
        initialBase:[],
        base: [],
        initialSpecialty:[],
        specialty: [],
        stateDropdownActive: true,
        countryDropdownActive: true,
        baseDropdownActive: true,
        specialtyDropdownActive: true,
        nameInputActive: true,
        handlingState:false,
        handlingCountry:false,
        handlingBase:false,
        keyVisible:false,
        cardCloseFlag:false,
    }),
    actions: {
        resetDropdownValues(){
           this.country=this.initialCountry;
           this.state=this.initialState;
           this.base=this.initialBase;
           this.specialty=this.initialSpecialty; 
        },
        determineMobile() {
            const minWidth = 768;
            this.isMobile = window.innerWidth < minWidth;
        },
        updateFilteredPhotogaphers(filteredPhotographers){
            this.filteredPhotographers=filteredPhotographers
        },
        updateAllPhotographers(allPhotographers){
            this.allPhotographers=allPhotographers
        },
        setSelectedCard(card, scrollPosition){
           this.savedScrollPosition = scrollPosition
           console.log("Scroll:",scrollPosition)
           this.selectedCard = card
        },
        clearSelectedCard() {
          this.selectedCard = null;
          this.cardCloseFlag = true;
        },        
        handleNavToPhotogs(router, origin) {
          const type = origin;
          if (this.selectedCard) {
            this.clearSelectedCard();
          }
          if (type==='mobile'){
            router.push('mobileSearchTool')
            this.selectedCard=null
            this.isMenuOpen = false
          }else {
            router.push('searchTool');
          }
        },
        resetFilters() {
          this.filterC = '';
          this.filterSt = '';
          this.filterB = '';
          this.filterSp = '';
          this.filterN = '';
        },
        freezeDropdown(dropdownName) {
          switch (dropdownName){
            case 'stateDropdown':
              this.stateDropdownActive = false;
              break;
            case 'countryDropdown':
              this.countryDropdownActive = false;
              break;
            case 'baseDropdown':
              this.baseDropdownActive = false;
              break;
            case 'specialtyDropdown':
              this.specialtyDropdownActive = false;
              break;
            case 'nameInput':
              this.nameInputActive = false;
              break;
          }
        },
        unfreezeDropdown(dropdownName) {
          switch (dropdownName){
            case 'stateDropdown':
              this.stateDropdownActive = true;
              break;
            case 'countryDropdown':
              this.countryDropdownActive = true;
              break;
            case 'baseDropdown':
              this.baseDropdownActive = true;
              break;
            case 'specialtyDropdown':
              this.specialtyDropdownActive = true;
              break;
            case 'nameInput':
              this.nameInputActive = true;
              break;
          }
        },
        freezeAllDropdowns(){
          this.stateDropdownActive = false;
          this.countryDropdownActive = false;
          this.baseDropdownActive = false;
          this.specialtyDropdownActive = false;
          this.nameInputActive = false;
        },
        unfreezeAllDropdowns(){
          this.stateDropdownActive = true;
          this.countryDropdownActive = true;
          this.baseDropdownActive = true;
          this.specialtyDropdownActive = true;
          this.nameInputActive = true;
        },
        triggerResetFilters() {
          this.unfreezeAllDropdowns();
          this.clearSelectedCard();
          this.resetFilters();
          this.resetFiltersFlag = !this.resetFiltersFlag;
        },
        keyChange(){
            let statusCountry, statusState, statusBase;

            if (this.filterC === 'Country' || this.filterC === ''){
              statusCountry = false
            } else {
              statusCountry = true
            }
           
            if (this.filterSt === 'State/Region' || this.filterSt === ''){
              statusState = false
            } else {
              statusState = true
            }

            if (this.filterB === 'Current Base' || this.filterB === ''){
              statusBase = false
            } else {
              statusBase = true
            }
            
            if (statusBase) {
              this.keyVisible = false
              return
            }
            if (statusState || statusCountry) {
              this.keyVisible = true
            } else {
              this.keyVisible = false
            }
         }
    },
    getters: {

    }
})