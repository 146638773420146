<script setup>
  import { ref, watch, onMounted, onBeforeUnmount, defineProps, defineEmits, defineExpose } from 'vue';

  const props = defineProps({
    title: String,
    items: Array,
    modelValue: String,
    isActive: Boolean
  });

  const emit = defineEmits(['update:modelValue']);


  const menu = ref(null);
  const isOpen = ref(false);
  const selectedOption = props.items.length>0 ? ref(props.items[0].title) : 'None'

  watch(selectedOption, (newValue) => {
    emit('update:modelValue', newValue);
  });

  const handleClickOutside = (event) => {
    if (menu.value && !menu.value.contains(event.target)) {
      closeDropdown();
    }
  };

  const toggleOpen = () => {
    isOpen.value = !isOpen.value;
  };

  const selectOption = (option) => {
    selectedOption.value = option;
    isOpen.value = false;
    emit('update:modelValue', option);
  };

  const closeDropdown = () => {
    isOpen.value = false;
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  defineExpose({
    selectOption,
    closeDropdown
  });
</script>

<template>
  <div ref="menu" class="menu" @click="toggleOpen">
    <a class="text">{{ selectedOption }}</a>
    <svg v-if="items.length >0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#F4899C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M6 9l6 6 6-6"></path>
    </svg>
    <transition name="fade" appear>
      <div class="sub-menu" v-if="isOpen">
        <div v-for="(item, i) in items" :key="i" class="sub-menu-item">
          <a @click="selectOption(item.title)">{{ item.title }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
  .menu{
    width:276px;
    height:40px;
    background-color: #bbdef0;
    display:flex;
    position: relative;
  }
  .menu:hover{
    background-color: #9ac7dd;
  }
  .menu .text{
    width:100%;
    background-color: #bbdef0;
    height: 30px;
    padding-top:10px;
    padding-left:5px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .menu .text:hover{
    background-color: #9ac7dd;
  }
  svg{
    position: absolute;
    left: 245px;
    width:30px;
    height:30px;
    margin-top:5px;
  }
  
  .sub-menu{
    position: absolute;
    top: 40px;
    width:276px;
    left: 138px;
    transform: translateX(-50%);
    z-index: 4;
    text-align: left;
    background-color: #bbdef0;
  }
  .sub-menu .sub-menu-item{
    height:30px;
    padding-left:5px;
  }
  .sub-menu .sub-menu-item:hover{
    background-color: #9ac7dd;
  }

  @media (max-width: 767px) {
    .menu{
      width:200px;
    }
    .menu .text{
      font-family: 'montserrat';
      padding-left: 26px;
      padding-right: 26px;
      text-align: center;
    }
    svg{
      left: calc(200px - 30px);
    }
    .sub-menu{
      text-align: center;
      width: 200px;
      left:100px;
      font-family: 'montserrat';
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-top:solid #0081A7;
      border-top-width: 2px;
    }
    .sub-menu .sub-menu-item{
      margin-top: 3px;
      height:30px;
      padding-left:0px;
    }
    .sub-menu .sub-menu-item a{
      display: block;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
}
</style>